/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'assets/fonts/style.css';

ion-menu-button.md {
  --border-radius: 10px;
  margin: 10px;
  --background: var(--ion-color-primary);
  --color: white;
}

.header-md::after {
  background-image: none;
}

ion-backdrop {
  background: white !important;
}
.my-custom-modal-css .modal-wrapper {
  max-height: 100%;

  top: 0%;
  position: absolute;
  display: block;
  border-radius: 15px;
  border-style: solid;
  border-width: 4px;
  // border-color: var(--ion-color-secondary);
  // background-color: #FF7C37 !important;
}
.my-custom-modal-css .modal-body {
  background-color: #ff7c37 !important;
}

.swiper-pagination {
  width: 50% !important;
  left: 25% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  transition:
    width 0.2s ease-in-out,
    height 0.2s ease-in-out;
}

.swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  background: transparent;
  border: 2px solid var(--bullet-background-active);
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 0.5em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  box-shadow: inset;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: #585b8f99 !important;
  outline: 2px solid #585b8f33 !important;
}

.terminos-class {
  .alert-wrapper {
    max-width: 70vw;
    min-height: 70vh;
  }

  .alert-message {
    min-height: 50vh;
  }

  div::-webkit-scrollbar,
  .alert-checkbox-group::-webkit-scrollbar {
    width: 0.5em;
    display: block !important;
  }

  div::-webkit-scrollbar-track,
  .alert-checkbox-group::-webkit-scrollbar-track {
    box-shadow: inset;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
  }

  div::-webkit-scrollbar-thumb,
  .alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: #585b8f99 !important;
    outline: 2px solid #585b8f33 !important;
  }
}

.modalConfirmacion {
  --width: 400px;
  --height: 400px;
  --border-radius: 15px;
  padding: 20px;
}
